import "./vendors.js";
import "./css/index.css";
import "./css/landing.css";
import image from './assets/Logo/JPG/nuhieh_Artboard 3 copy.jpg';

import style1 from './assets/images/متكيف.PNG'
import style3 from './assets/images/حساس و مزكز على طلاب.PNG'
import style2 from './assets/images/معلم رسمي.PNG'
import style4 from './assets/images/مركز على حقائق.PNG'
import style5 from './assets/images/فردي.PNG'

import Swal from 'sweetalert2';


// // Function to initialize navigation links with redirection logic based on login status
// function initNavigation() {
//     const homeLink = document.querySelector(".nav-link.home");
//     const blogLink = document.querySelector(".nav-link.blog");

//     // Set default active page (Home)
//     setActiveLink(homeLink);

//     // Event listener for home link
//     homeLink.addEventListener("click", () => {
//         setActiveLink(homeLink);
//     });

//     // Event listener for blog link with login check
//     blogLink.addEventListener("click", (e) => {
//         e.preventDefault();
//         const token = sessionStorage.getItem("token");
//         const user = sessionStorage.getItem("userId");

//         if (token && user) {
//             setActiveLink(blogLink);
//             window.location.href = "Blog.html";
//         } else {
//             sessionStorage.setItem("redirect", "Blog.html");
//             window.location.href = "LogIn.html";
//         }
//     });
// }

// // Helper function to set active link
// function setActiveLink(link) {
//     document.querySelectorAll(".nav-link").forEach((navLink) => {
//         navLink.classList.remove("active");
//     });
//     link.classList.add("active");
// }



// // Function to initialize the "User Account" link with redirection logic
// function initUserAccount() {
//     document.querySelectorAll("#useraccount").forEach((element) => {
//         element.addEventListener("click", () => {
//             const token = sessionStorage.getItem("token");
//             const user = sessionStorage.getItem("userId");

//             if (token && user) {
//                 window.location.href = "profile.html";
//             } else {
//                 sessionStorage.setItem("redirect", "profile.html");
//                 window.location.href = "LogIn.html";
//             }
//         });
//     });
// }

// // Function to initialize the "Try Nuhieh" link with redirection logic
// function initTryNuhieh() {
//     document.querySelectorAll("#tryNuhieh").forEach((element) => {
//         element.addEventListener("click", () => {
//             const token = sessionStorage.getItem("token");
//             const user = sessionStorage.getItem("userId");

//             if (token && user) {
//                 window.location.href = "tool.html";
//             } else {
//                 sessionStorage.setItem("redirect", "tool.html");
//                 window.location.href = "LogIn.html";
//             }
//         });
//     });
// }
// Function to slide the uderDev in the landing page

// Questions list 
const questions = [
    { question: 'أغيّر أسلوبي في التدريس حسب الطلاب.', score: 0 },
    { question: 'أشعر بعدم الارتياح عند تقديم عروض مباشرة على عكس التدريس من خلال الألعاب والتمارين.', score: 0 },
    { question: 'أفضل التدريس من خلال الألعاب لتوصيل المعلومات.', score: 0 },
    { question: 'أحب أن تكون لدي أهداف أساسية تحدد مسار التعلم.', score: 0 },
    { question: 'أفضل أن تكون الجلسات التعليمية كافية ذاتيًا بدون متابعة لاحقة.', score: 0 },
    { question: 'الوسائل الإيضاحية تشتت الانتباه أثناء الشرح.', score: 0 },
    { question: 'أركز في تحضيري للتدريس على نفسي وما سأقدمه.', score: 0 },
    { question: 'أَفْضل الدروس هي التي تنقل الحقائق بشكل مباشر وواضح.', score: 0 },
    { question: 'أتحاشى التشتت وأفضل أن أدير الجلسات التعليمية بالطريقة التي خططت لها.', score: 0 },
    { question: 'أحبّ تعليم المهارات الحياتية.', score: 0 },
    { question: 'لا أهتم كثيرًا بأن أكون موظفًا رسميًا كمعلم.', score: 0 },
    { question: 'أُقدم المعلومات بنفس الطريقة بغض النظر عن من هم الطلاب.', score: 0 },
    { question: 'أحب إعطاء الفرصة للطلاب لاستكشاف طرق التعلم بأنفسهم.', score: 0 },
    { question: 'طورت أسلوبي الخاص في التدريس.', score: 0 },
    { question: 'أفضل التدريس الفردي.(تدريس مباشر لطالب واحد فقط)', score: 0 },
    { question: 'أعتبر تحفيز المشاعر من خلال اللعب بالأدوار أو التمثيل هو جانب قيم في التدريس.', score: 0 },
    { question: 'أشعر بالراحة باستخدام الفكاهة أثناء التدريس.', score: 0 },
    { question: 'من المهم بالنسبة لي أن يكون تدريسي مُعترفًا به من قبل جهة رسمية. (مثال: مدرّس آبل محترف)', score: 0 },
    { question: 'أشعر بعدم الراحة عند التدريس لمجموعات بتخصصات مختلفة.', score: 0 },
    { question: 'أكون في أفضل حالاتي عند تخطيط تدريسي وفقًا للمناهج الرسمية أو الهياكل التنظيمية.', score: 0 }
];

// techering style list 
const teachingStyle = [
    {
        style: 'المعلم المتنوع والمتكيف ',
        description: 'أنت معلمة/معلم تمتلك العديد من المهارات المختلفة، يمكنك التدريس لكل من الأقران والمبتدئين، واعية جدًا بالبيئة التعليمية ككل وباحتياجات المتعلمين.',
        strength: '“قدرتك على التكيف واستخدام مهاراتك المتنوعة تجعل منك معلمة متميزة. استمري في إلهام طلابك بمرونتك وإبداعك!”',
        score: [1, 10, 14, 17],
        img: style1
    },
    {
        style: ' المعلم الحساس والمركز على الطلاب',
        description: 'أنت معلمة/معلم تركز بشكل كبير على الطلاب، تفضل التدريس في مجموعات صغيرة، تستخدم اللعب بالأدوار والدراما، ولا تشعر بالراحة عند تقديم العروض المباشرة.',
        strength: '“اهتمامك العميق بطلابك وقدرتك على خلق بيئة تعليمية داعمة تجعل منك معلمة رائعة. استمري في تقديم الدعم والتشجيع لطلابك!”',
        score: [2, 3, 16, 13],
        img: style3
    },
    {
        style: ' المعلم الرسمي',
        description: 'أنت معلمة/معلم مستعدة جيدًا، مُعتَمَدة، وواعيةٌ جدًا بالمناهج الرسمية وتتبعين الأهداف الأساسية للتدريس.',
        strength: '“تحضيرك الجيد والتزامك بالمناهج الرسمية يعكس احترافيتك العالية. استمري في تقديم تعليم موثوق وممنهج!”',
        score: [4, 7, 18, 20],
        img: style2
    },
    {
        style: ' المعلم المباشر الذي يركز على الحقائق',
        description: 'أنت معلمة/معلم تحب تدريس الحقائق الواضحة، تتحدث بشكل مباشر، وتركز على المهارات المحددة، وتفضل عدم الانخراط في التدريس والتعلم متعدد التخصصات.',
        strength: '“وضوحك وتركيزك على الحقائق يساعد طلابك على فهم المعلومات بشكل أفضل. استمري في تقديم الحقائق بدقة وفعالية!”',
        score: [9, 8, 12, 19],
        img: style4
    },
    {
        style: ' المعلم الفردي',
        description: 'أنت معلمة/معلم تفضلي تقديم أجزاء صغيرة من التدريس المحدد والمنفصل، بشكل فردي، بدون استخدام وسائل مساعدة وبدون متابعة لاحقة.',
        strength: '“قدرتك على تقديم تعليم فردي ومحدد تجعل منك معلمة فريدة. استمري في تقديم الدعم الشخصي لطلابك ومساعدتهم على النجاح!”',
        score: [5, 6, 11, 15],
        img: style5
    }

]

// Initialize DOM elements
const quizContainer = document.getElementById('quiz-container');
const resultContainer = document.getElementById('result-container');
let progressContainer = document.getElementById('progress-container');

let currentQuestion = 0;
let styleScores = Array(teachingStyle.length).fill(0);

const totalQuestions = questions.length-1;
const progressBar = document.getElementById("progressBar1");

function updateProgressBar() {
    // console.log(progressBar)
    const progressPercentage = (currentQuestion / totalQuestions) * 100;
    // console.log(progressPercentage)
  progressBar.style.width = progressPercentage + "%";
}
// Show the current question
function showQuestion() {
    quizContainer.innerHTML = '';

    // change progressContainer display to block
    progressContainer.style.display = 'block';

    const questionNumber = currentQuestion + 1; // Calculate question number
    const questionDiv = document.createElement('div');
    questionDiv.innerHTML = `
    
        <h3>${questionNumber}. ${questions[currentQuestion].question}</h3>
    `;
    quizContainer.appendChild(questionDiv);

    createCircles();
    updateProgressBar();

}

function createCircles() {
    const circleContainer = document.createElement('div');
    circleContainer.className = 'circle-container';
    circleContainer.style.display = 'flex';
    circleContainer.style.justifyContent = 'space-evenly';
    circleContainer.style.alignItems = 'center';
    circleContainer.style.marginTop = '10px';
    circleContainer.style.width = '80%';
    circleContainer.style.direction = 'ltr'; // Make layout Right-To-Left (RTL)

    // Define circle sizes (large -> medium -> small -> medium -> large)
    const sizes = ['60px', '45px', '35px', '45px', '60px'];
    const colors = ['#967DA5', '#CFBFD5', 'gray', '#DDEEE4', '#b1ded2'];

    // Create circles dynamically
    sizes.forEach((size, index) => {
        const circle = document.createElement('div');
        circle.className = `circle`;
        circle.style.width = size;
        circle.style.height = size;
        circle.style.borderColor = colors[index];
        circle.style.margin = '5px';

        // Hover effect to change the color of the circle and reset on mouse out
        circle.onmouseover = () => {
            circle.style.backgroundColor = colors[index];
        };
        circle.onmouseout = () => {
            circle.style.backgroundColor = 'white';
        };

        // Add click event
        circle.onclick = () => {
            console.log(`Selected value: ${index + 1}`);
            handleAnswer(index + 1); // Call handleAnswer with the selected score
        };
        circleContainer.appendChild(circle); // Add to the container
    });

    // Append circle container
    quizContainer.appendChild(circleContainer);

    // Add labels for AGREE and DISAGREE
    const labelContainer = document.createElement('div');
    labelContainer.className = 'label-container';
    labelContainer.style.display = 'flex';
    labelContainer.style.justifyContent = 'space-between';
    labelContainer.style.width = '80%';
    labelContainer.style.marginTop = '10px';
    labelContainer.style.direction = 'rtl'; // RTL for label text alignment

    const rightLabel = document.createElement('span'); // Adjust for RTL (right aligns with first)
    rightLabel.className = 'label-green';
    rightLabel.textContent = 'ينطبق بشدة'; // Arabic text for AGREE

    const leftLabel = document.createElement('span'); // Adjust for RTL (left aligns with last)
    leftLabel.className = 'label-purple';
    leftLabel.textContent = 'لا ينطبق إطلاقًا'; // Arabic text for DISAGREE

    labelContainer.appendChild(rightLabel); // Append to label container
    labelContainer.appendChild(leftLabel);

    // Append labels below the circles
    quizContainer.appendChild(labelContainer);
}

// Handle answer selection
function handleAnswer(score) {
    questions[currentQuestion].score = score;
    teachingStyle.forEach((style, index) => {
        if (style.score.includes(currentQuestion + 1)) {
            styleScores[index] += score;
        }
    });

    currentQuestion++;
    if (currentQuestion < questions.length) {
        showQuestion();
    } else {
        progressContainer.style.display = 'none';
        showResultsQ();
    }
}

function showResultsQ() {
    // Hide the quiz container
    quizContainer.style.display = 'none';

    // Calculate the total score
    const totalScore = styleScores.reduce((acc, score) => acc + score, 0);

    // Normalize scores to ensure total is 100%
    const normalizedScores = styleScores.map(score => (score / totalScore) * 100);

        // Get the index of the highest style score
        const highestStyleIndex = getHighestStyleIndex(normalizedScores);

        // Save the highest style in local storage
        const highestStyle = teachingStyle[highestStyleIndex];
    // sessionStorage.setItem('highestTeachingStyle', highestStyle.style);
    let teaching_style = JSON.parse(sessionStorage.getItem('teaching_style'));

    if (teaching_style) {
        console.log("teaching_style sesstionStorge: " + teaching_style);
        let points = JSON.parse(sessionStorage.getItem('points'));
        console.log("points: " + points);
        points = !teaching_style[1] ? points + 25 : points;
        console.log("updated points: " + points);
        sessionStorage.setItem('points', points);
        sessionStorage.setItem('teaching_style', JSON.stringify([highestStyle.style, false]));
    }

    

    // Display the results dynamically
    resultContainer.style.display = 'block';
    resultContainer.innerHTML = `
        <div class="d-flex flex-wrap flex-lg-nowrap w-75 mx-auto shadow p-4 results-container mb-5" style="background-color: #F9F9F9; border-radius: 20px;">
            <!-- Types and Progress Bars -->
            <div class="w-100 w-lg-50 px-3">
                ${teachingStyle
            .map((style, index) => {
                const percentage = normalizedScores[index].toFixed(1); // Use normalized scores
                return `
                            <div class="mb-3 personality-type ${index === getHighestStyleIndex(normalizedScores) ? 'selected' : ''}" data-type="${style.style}" id="personalityType${index}" style="background-color: ${index === getHighestStyleIndex(normalizedScores) ? '#EAEAEA' : 'transparent'
                    }; border-radius: 10px; padding: 5px;">
                                <p class="mb-1" style="font-weight: 600; color: #333;">${style.style}</p>
                                <div class="progress" style="height: 10px; background-color: #EAEAEA;">
                                    <div class="progress-bar" role="progressbar" 
                                        style="width: ${percentage}%; background-color: ${getColor(index)};" 
                                        aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100">
                                    </div>
                                </div>
                                <p class="mt-1 mb-0 text-end" style="font-size: 0.9rem; color: #555;">${percentage}%</p>
                            </div>
                        `;
            })
            .join('')}
            </div>
            <!-- Description Section -->
            <div class="w-100 w-lg-50 p-4 d-flex flex-column align-items-center text-center description-container" style="background-color: #FFFFFF; border-radius: 15px;" id="personalityDescription">
                ${generateDescriptionHTML(getHighestStyleIndex(normalizedScores))}
            </div>
        </div>

        <!-- Button to retake the quiz -->
        <div class=" my-4  text-center">
            <a class="retake " id="retake">
            إعادة الاختبار                        
            </a>
            <a class="retake ms-3" id="share">
            مشاركة الاختبار
            </a>
        </div>
    `;

    // Add event listeners for each personality type
    teachingStyle.forEach((style, index) => {
        const element = document.getElementById(`personalityType${index}`);
        if (element) {
            element.addEventListener('click', () => updateSelection(index));
        }
    });
    document.querySelector('#retake').addEventListener('click', resetQuiz);
    document.querySelector('#share').addEventListener('click', () => {
        shareQuizResults(highestStyle);
    });
        shareQuizResults(highestStyle);
}

function shareQuizResults (highestStyle) {
    Swal.fire({
        title: `:نمط تدريسك هو`,
        html: `
            <h3 class="text-center my-3 fs-4" style="color: #754E8F;">${highestStyle.style}</h3>
            <img src="${highestStyle.img}" alt="${highestStyle.style}" class="img-fluid my-3" style="border-radius: 15px; max-height: 200px;">
            <p class="text-justify" style="color: #555; background-color: #f9f9f9; padding: 10px; border-radius: 10px;">
                ${highestStyle.description}
            </p>
            <div class="mt-3 d-flex justify-content-center" style="margin-top: 1.5rem; display: flex; justify-content: center; gap: 10px;">
                <button id="shareWhatsApp" class="btn btn-light btn-sm mx-2" 
                    style="border: 1px solid #ddd; border-radius: 8px; padding: 10px 15px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); transition: all 0.3s ease-in-out;">
                    <i class="fa-brands fa-whatsapp" style="font-size: 1.5rem; color: #555; transition: color 0.3s ease-in-out;"></i>
                </button>
                <button id="shareTwitter" class="btn btn-light btn-sm mx-2" 
                    style="border: 1px solid #ddd; border-radius: 8px; padding: 10px 15px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); transition: all 0.3s ease-in-out;">
                    <i class="fa-brands fa-square-x-twitter" style="font-size: 1.5rem; color: #555; transition: color 0.3s ease-in-out;"></i>
                </button>
                <button id="shareLinkedIn" class="btn btn-light btn-sm mx-2" 
                    style="border: 1px solid #ddd; border-radius: 8px; padding: 10px 15px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); transition: all 0.3s ease-in-out;">
                    <i class="fa-brands fa-linkedin" style="font-size: 1.5rem; color: #555; transition: color 0.3s ease-in-out;"></i>
                </button>
                <button id="copyLink" class="btn btn-light btn-sm mx-2" 
                    style="border: 1px solid #ddd; border-radius: 8px; padding: 10px 15px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); transition: all 0.3s ease-in-out;">
                    <i class="fa-solid fa-copy" style="font-size: 1.5rem; color: #555; margin-right: 5px; transition: color 0.3s ease-in-out;"></i> نسخ
                </button>
            </div>
        `,
        showCancelButton: false,
        showConfirmButton: false,
        didRender: () => {
            const message = `لقد أنهيت للتو اختبار نمط التدريس من موقع نُهيه 📚💜! جربه أنت أيضًا! \nنمط تدريسي هو ${highestStyle.style} \nرابط الاختبار: ${window.location.href}`;
    
            // WhatsApp sharing
            document.getElementById('shareWhatsApp').addEventListener('click', () => {
                const whatsAppShareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                window.open(whatsAppShareURL, '_blank');
            });
    
            // Twitter sharing
            document.getElementById('shareTwitter').addEventListener('click', () => {
                const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
                window.open(twitterShareURL, '_blank');
            });
    
            // LinkedIn sharing with two-step process
            document.getElementById('shareLinkedIn').addEventListener('click', async () => {
                // First copy the message to clipboard
                await navigator.clipboard.writeText(message);
                
                // Show instruction modal
                Swal.fire({
                    title: 'مشاركة على LinkedIn',
                    html: `
                        <p>تم نسخ الرسالة! اتبع الخطوات التالية:</p>
                        <ol style="text-align: right; margin-top: 1rem;">
                            <li>سيتم توجيهك إلى LinkedIn</li>
                            <li>انقر على "ابدأ منشورًا"</li>
                            <li>الصق الرسالة (Ctrl+V أو ⌘+V)</li>
                        </ol>
                    `,
                    icon: 'info',
                    confirmButtonText: 'فتح LinkedIn',
                    showCancelButton: true,
                    cancelButtonText: 'إلغاء'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(message)}`;
                        window.open(linkedInShareURL, '_blank');                    }
                });
            });
    
            // Copy full message
            document.getElementById('copyLink').addEventListener('click', () => {
                navigator.clipboard.writeText(message).then(() => {
                    // Change button text temporarily to indicate success
                    const copyButton = document.getElementById('copyLink');
                    const originalHTML = copyButton.innerHTML;
                    copyButton.innerHTML = '<i class="fa-solid fa-check" style="font-size: 1.5rem; color: #28a745;"></i> تم النسخ';
                    
                    setTimeout(() => {
                        copyButton.innerHTML = originalHTML;
                    }, 2000);
    
                    // Show success toast
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
    
                    Toast.fire({
                        icon: 'success',
                        title: 'تم نسخ الرسالة بنجاح'
                    });
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'خطأ!',
                        text: 'لم يتم نسخ الرسالة. حاول مرة أخرى.',
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                    });
                });
            });
    
            // Add hover effects for buttons
            const buttons = document.querySelectorAll('.btn-light');
            buttons.forEach(button => {
                button.addEventListener('mouseover', () => {
                    button.style.transform = 'translateY(-2px)';
                    button.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.15)';
                    const icon = button.querySelector('i');
                    if (icon) {
                        icon.style.color = '#754E8F';
                    }
                });
    
                button.addEventListener('mouseout', () => {
                    button.style.transform = 'translateY(0)';
                    button.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.1)';
                    const icon = button.querySelector('i');
                    if (icon) {
                        icon.style.color = '#555';
                    }
                });
            });
        }
    });
}
// retake the quize
function resetQuiz() {
    // Reset question index
    currentQuestion = 0;

    // Reset scores for all questions
    questions.forEach(question => (question.score = 0));

    // Reset style scores
    styleScores = Array(teachingStyle.length).fill(0);

    // Clear result container and show quiz container
    resultContainer.style.display = 'none';
    quizContainer.style.display = 'flex';

    // Start the quiz again
    showQuestion();
}


// Function to update the selection dynamically
function updateSelection(index) {
    // Update the description section
    const descriptionContainer = document.querySelector('#personalityDescription');
    descriptionContainer.innerHTML = generateDescriptionHTML(index);

    // Highlight the selected type
    document.querySelectorAll('.personality-type').forEach(el => {
        el.classList.remove('selected');
        el.style.backgroundColor = 'transparent';
    });

    const selectedElement = document.querySelector(`.personality-type[data-type="${teachingStyle[index].style}"]`);
    selectedElement.classList.add('selected');
    selectedElement.style.backgroundColor = '#EAEAEA';
}

// Helper function to generate the description HTML
// Updated generateDescriptionHTML function to include colors in the description
function generateDescriptionHTML(index) {
    return `
        <h3 class="text-center my-3 fs-4" style="color: #754E8F;">${teachingStyle[index].style}</h3>
        <img src="${teachingStyle[index].img}" alt="${teachingStyle[index].style}" width="200" height="200" class="img-fluid my-3" style="border-radius: 15px;">
        <p class="text-justify" style="color: #555; background-color: #f9f9f9; padding: 10px; border-radius: 10px;">
            ${teachingStyle[index].description}
        </p>
        <strong style="color: #967DA5;">${teachingStyle[index].strength}</strong>
    `;
}


// Helper function to get the color for progress bars
function getColor(index) {
    const colors = ['#9e85b5', '#f6e0ae', '#b1ded2', '#fbd3d1', '#92c5ea'];
    return colors[index % colors.length];
}

// Helper function to get the index of the highest style score
function getHighestStyleIndex(scores) {
    return scores.indexOf(Math.max(...scores));
}


function logosSlide() {
    const uderDevContainer = document.querySelector(".uderDev");
    const uderDevSlide = document.querySelector(".uderDev-slide");


    if (uderDevContainer && uderDevSlide) {
        const clone = uderDevSlide.cloneNode(true);
        uderDevContainer.appendChild(clone);
        // Force reflow to ensure animation starts in production
        const reflow = uderDevSlide.offsetHeight;
        uderDevSlide.style.animation = "scroll 35s infinite linear";
    }

    const logoContainer = document.querySelector(".logos");
    const logoSlide = document.querySelector(".logos-slide");

    
    if (logoContainer && logoSlide) {
        const clone = logoSlide.cloneNode(true);
        logoContainer.appendChild(clone);
        // Force reflow to ensure animation starts in production
        const reflow = logoSlide.offsetHeight;
        logoSlide.style.animation = "scroll 35s infinite linear";

    }
}

// Ensure the script runs after DOM content is fully loaded

// get the token and user from the local storage
var postsArray = [];

function calculateReadingTime(text) {
    if (!text) {
        return "اقل من ١";
    } else {
        const wordsPerMinute = 250; // Average reading speed (words per minute)
        const textLength = text.trim().split(/\s+/).length; // Split text by whitespace and count words
        const readingTimeMinutes = Math.ceil(textLength / wordsPerMinute); // Calculate minutes, round up
        return readingTimeMinutes;
    }
    cz;
}

function formatPosts(posts) {
    return posts.map((post) => {
        // convert the date to a readable format in Arabic
        post.created_at = new Date(Date.parse(post.created_at)).toLocaleDateString(
            "ar-EG",
            {
                year: "numeric",
                month: "long",
                day: "numeric",
            }
        );
        // calculate the reading time for each post ensuring it's in Arabic and wrpped in `` to be a string
        post.readTime = `${calculateReadingTime(post.body)}د للقراءة`;

        // console.log(post.body);
        post.body = post.body.replace(/\n/g, "<br><br>").replace(/(\d\.)/g, "<br><br><strong>$1</strong>"); // Emphasize steps with numbers

        // console.log(post.body);

        // update the strcture of the post object to match the HTML structure in the gridView function
        postsArray.push({
            id: post.id,
            title: post.title,
            image: post.image,
            category: post.category,
            readTime: post.readTime,
            created_at: post.created_at,
            created_by: post.created_by,
            body: post.body,
        });
    });
}

// function to view 5 posts in the landing page , and handle if the posts are less than 5 
function postsView() {
    var postContainer = document.querySelector(".landingPosts");
    postContainer.innerHTML = "";
    // use Switch to handle the number of posts , use innerHtml to add the posts to the container 
    switch (postsArray.length) {
        case 0:
            postContainer.innerHTML = `
            <div class="row my-3">
            <h1 class="text-center">لا يوجد منشورات</h1>
            </div>
            `;
            break;
        case 1:
            postContainer.innerHTML = `
            <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 2:
            postContainer.innerHTML = `
            <div class="row mt-4  R2">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 3:
            postContainer.innerHTML = `
                        <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 4:
            postContainer.innerHTML = `
            <div class="row mt-4  R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5 R3">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[3].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[3].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[3].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        default:
            postContainer.innerHTML = `
            <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5 R3">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[4].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[4].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[4].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[3].id}'">
                            <img class="mh-75 object-fit-cover" src="${postsArray[3].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[3].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
    }

    // Select the blog element and check its existence
    var goToBlog = document.querySelector(".restOfBlog");

    if (goToBlog) {
        goToBlog.addEventListener("click", function () {
            // Retrieve and log the token and userId for debugging

            const token = sessionStorage.getItem("token");
            const user = sessionStorage.getItem("userId");
            console.log("Token:", token);
            console.log("User ID:", user);

            // Redirect to login if token or userId is missing
            if (!token || !user) {
                sessionStorage.setItem("redirect", "Blog.html"); // Store redirect path
                console.log(sessionStorage.getItem("redirect"));
                console.log("User not logged in. Redirecting to LogIn page.");
                window.location.href = "LogIn.html";
            } else {
                console.log("User logged in. Redirecting to Blog page.");
                window.location.href = "Blog.html";
            }
        });
    } else {
        console.error("Element '.restOfBlog' not found. Make sure the selector is correct.");
    }

}

// get posts from the backend
async function getPosts() {
    if (!navigator.onLine) {
        alert("لا يوجد اتصال بالإنترنت");
        return;
    }
    try {
        const response = await fetch(
            "https://nuhieh-backend-g6cybsc7e9dqerh8.canadacentral-01.azurewebsites.net/blog/posts"
        );
        if (!response.ok) {
            console.log("!response.ok: " + response.error);
            const errorText = await response.text();
            console.log("فشل جلب المنشورات: " + errorText);
            return;
        }
        const responseData = await response.json();
        if (responseData.error) {
            console.log("error: " + responseData.error);
        } else {
            formatPosts(responseData.data);
            postsView();
            console.log("postsArray.length: " + postsArray.length);
        }
    } catch (error) {
        console.log("catch: " + error.message);
    }
}



// Initialize the entire landing page functionality
async function initLandingPage() {
const { initNavigation, initUserAccount, initTryNuhieh ,initElapsedTimeTracking} = await import("./shared");

    initNavigation();
    initUserAccount();
    initTryNuhieh();
    // Initialize elapsed time tracking 
    // initInfiniteSlider();
    switchTheme();
if (sessionStorage.getItem("userId")) {
    await initElapsedTimeTracking();
}

    if (window.location.pathname.includes("index.html")) {
        getPosts();
        // logosSlide();
        // scrollLogos();
        // cloneLogos();
        initInfiniteSlider();

        // const JoinUs = document.querySelector(".JoinUs");

    }
    // call showQuestion(); on TechetingStyleQuiz.html 
    if (window.location.pathname.includes("TechetingStyleQuiz.html")) {
        const startQuiz = document.querySelector("#startQuiz");
        const explainingdiv = document.querySelector(".explaining");
        startQuiz.addEventListener("click", function () {
            explainingdiv.style.display = "none";
            showQuestion();
        });
    }
}

document.addEventListener("DOMContentLoaded", initLandingPage);
// const uderDevslider = document.querySelector('.uderDev-slide');
const logoSlider = document.querySelector('.logos-slide');

function cloneLogos() {
    // Clone the uderDev for seamless scrolling
    const uderDevclones = [...uderDevslider.children].map((child) => child.cloneNode(true));
    uderDevclones.forEach((clone) => uderDevslider.appendChild(clone));

    // Clone the logos for seamless scrolling
    const logoClones = [...logoSlider.children].map((child) => child.cloneNode(true));
    logoClones.forEach((clone) => logoSlider.appendChild(clone));
}

// let sliderScrollPosition = 0;
let logosliderScrollPosition = 0;
function scrollLogos() {
    // sliderScrollPosition += 1; // Adjust speed by changing this value
    // uderDevslider.style.transform = `translateX(${sliderScrollPosition}px)`;

    // Reset position for seamless scroll
    // if (Math.abs(sliderScrollPosition) >= uderDevslider.scrollWidth / 2) {
    //     sliderScrollPosition = 0;
    // }

    logosliderScrollPosition += 1; // Adjust speed by changing this value

    logoSlider.style.transform = `translateX(${logosliderScrollPosition}px)`;
    
    // Reset position for seamless scroll
    if (Math.abs(logosliderScrollPosition) >= logoSlider.scrollWidth / 2) {
        logosliderScrollPosition = 0;
    }
    requestAnimationFrame(scrollLogos);
}


function initInfiniteSlider() {
    // const uderDevSlider = document.querySelector('.uderDev-slide');
    const logoSlider = document.querySelector('.logos-slide');

    // Function to clone children for seamless scrolling
    function cloneSliderContent(slider) {
        const clones = [...slider.children].map((child) => child.cloneNode(true));
        clones.forEach((clone) => slider.appendChild(clone));
    }

    // Clone the content for both sliders
    // cloneSliderContent(uderDevSlider);
    cloneSliderContent(logoSlider);

    let uderDevPosition = 0;
    let logoPosition = 0;

    // Function to animate sliders
    function animateSliders() {
        // const uderDevWidth = uderDevSlider.scrollWidth / 2;
        const logoWidth = logoSlider.scrollWidth / 2;

        // Adjust the positions for smooth scrolling
        // uderDevPosition -= 1; // Speed for uderDev slider
        logoPosition -= 1; // Speed for logo slider

        // uderDevSlider.style.transform = `translateX(${uderDevPosition}px)`;
        logoSlider.style.transform = `translateX(${logoPosition}px)`;

        // Reset positions for seamless loop
        // if (Math.abs(uderDevPosition) >= uderDevWidth) {
        //     uderDevPosition = 0;
        // }
        if (Math.abs(logoPosition) >= logoWidth) {
            logoPosition = 0;
        }

        requestAnimationFrame(animateSliders);
    }

    // Start the animation
    animateSliders();
}

// function to switch between the theme1 and theme2 
function switchTheme() {
    const themeSwitch = document.querySelectorAll("#themeSwitch");
    const theme1 = document.querySelectorAll(".viewThem1");
    const theme2 = document.querySelectorAll(".viewThem2");

    console.log(themeSwitch);
    console.log(theme1);
    console.log(theme2);
    themeSwitch.forEach((theme) => {
        theme.addEventListener("click", function () {

            theme1.forEach((element) => {
                element.classList.toggle("d-none");
            });
            theme2.forEach((element) => {
                element.classList.toggle("d-none");
            });
        });
    });
}
